/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RenterUserCreateModel
 */
export interface RenterUserCreateModel {
    /**
     * 
     * @type {string}
     * @memberof RenterUserCreateModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RenterUserCreateModel
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof RenterUserCreateModel
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RenterUserCreateModel
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof RenterUserCreateModel
     */
    companyName: string;
    /**
     * 
     * @type {boolean}
     * @memberof RenterUserCreateModel
     */
    requestOTP: boolean;
    /**
     * 
     * @type {string}
     * @memberof RenterUserCreateModel
     */
    agreementVersion: string;
    /**
     * 
     * @type {string}
     * @memberof RenterUserCreateModel
     */
    kvkkVersion: string;
    /**
     * 
     * @type {string}
     * @memberof RenterUserCreateModel
     */
    ipazVersion?: string | null;
}

export function RenterUserCreateModelFromJSON(json: any): RenterUserCreateModel {
    return RenterUserCreateModelFromJSONTyped(json, false);
}

export function RenterUserCreateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RenterUserCreateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'lastName': json['lastName'],
        'email': json['email'],
        'phone': json['phone'],
        'companyName': json['companyName'],
        'requestOTP': json['requestOTP'],
        'agreementVersion': json['agreementVersion'],
        'kvkkVersion': json['kvkkVersion'],
        'ipazVersion': !exists(json, 'ipazVersion') ? undefined : json['ipazVersion'],
    };
}

export function RenterUserCreateModelToJSON(value?: RenterUserCreateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'lastName': value.lastName,
        'email': value.email,
        'phone': value.phone,
        'companyName': value.companyName,
        'requestOTP': value.requestOTP,
        'agreementVersion': value.agreementVersion,
        'kvkkVersion': value.kvkkVersion,
        'ipazVersion': value.ipazVersion,
    };
}

