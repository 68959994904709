/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RenterCompanyCreateModel
 */
export interface RenterCompanyCreateModel {
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyCreateModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyCreateModel
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyCreateModel
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyCreateModel
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyCreateModel
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyCreateModel
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyCreateModel
     */
    agreementVersion: string;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyCreateModel
     */
    kvkkVersion: string;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyCreateModel
     */
    ipazVersion?: string | null;
}

export function RenterCompanyCreateModelFromJSON(json: any): RenterCompanyCreateModel {
    return RenterCompanyCreateModelFromJSONTyped(json, false);
}

export function RenterCompanyCreateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RenterCompanyCreateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'lastName': json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': json['phone'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'password': json['password'],
        'agreementVersion': json['agreementVersion'],
        'kvkkVersion': json['kvkkVersion'],
        'ipazVersion': !exists(json, 'ipazVersion') ? undefined : json['ipazVersion'],
    };
}

export function RenterCompanyCreateModelToJSON(value?: RenterCompanyCreateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'lastName': value.lastName,
        'email': value.email,
        'phone': value.phone,
        'companyName': value.companyName,
        'password': value.password,
        'agreementVersion': value.agreementVersion,
        'kvkkVersion': value.kvkkVersion,
        'ipazVersion': value.ipazVersion,
    };
}

