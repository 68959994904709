import { Col, Form, Row } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { useAtom } from "jotai";
import PrimaryButton from "../../../components/atomics/buttons/primary-button";
import { useQuery } from "../../../helpers/use-query";
import { extendedMachineDetailAtom, loadingAtom } from "../../../store/global-atoms";
import { t } from "../../../translate";
import { useSecureService } from "../../../store/service-atoms";
import {
    BasicValueModel,
    DocumentCategory,
    DocumentModel,
    MachineDocumentViewModel,
    MachineRentalTypeModel,
    MachineSaleCommissionViewModel,
    MachineSaleDepositFeeViewModel,
} from "../../../service";
import { useNavigate } from "react-router-dom";
import SaleMachineInfoPart from "../../../page-parts/sale-machine-info-part";
import SaleMachineAdditionalInfoPart from "../../../page-parts/sale-machine-additional-info-part";
import SaleMachinePricePart from "../../../page-parts/sale-machine-price-part";
import ListContainer from "../../../components/containers/list-container";
import SecondaryButton from "../../../components/atomics/buttons/secondary-button";
import { ReactComponent as Delete } from "../../../components/assets/delete.svg";

const EditSaleMachine = () => {
    const query = useQuery();
    const machineId = query.get("macid");
    const [form] = Form.useForm();
    const [editedMachine, editMachine] = useAtom(extendedMachineDetailAtom);
    const {
        doUpdateSaleMachine,
        doGetMachine,
        doUnApproveSaleMachine,
        doGetMachineCommissionsForFee,
        doGetMachineCommissionsForSale,
        doGetExchangeRates,
    } = useSecureService();
    const [docs, setDocs] = useState<MachineDocumentViewModel[]>([]);
    const [fees, setFees] = useState<MachineSaleDepositFeeViewModel[] | undefined>(undefined);
    const [selectedSubcategory, setSelectedSubcategory] = useState<{ catid: Number; subCatid: Number } | undefined>(undefined);
    const [commissions, setCommissions] = useState<MachineSaleCommissionViewModel[] | undefined>(undefined);
    const [exchangeRates, setExchangeRates] = useState<BasicValueModel[] | undefined>(undefined);
    const [totalPrice, setTotalPrice] = useState(0);
    const [priceCurrency, setPriceCurrency] = useState("");
    const navigate = useNavigate();
    const [loading] = useAtom(loadingAtom);
    const [dataFetching,setDataFetching] = useState(true);
    const [defaultCurrencyId, setDefaultCurrencyId] = useState(0);
    const pageKey = useRef(0); 

    const pics = useMemo(() => {
        return editedMachine?.documents?.filter((doc) => doc.documentCategory === "Photo");
    }, [editedMachine?.documents]);

    useEffect(() => {
        if (machineId) {
            getMachine(Number.parseInt(machineId));
        }
        const getCommissionsAndFees = async () => {
            setFees(await doGetMachineCommissionsForFee());
            setCommissions(await doGetMachineCommissionsForSale());
            setExchangeRates(await doGetExchangeRates());
        };
        getCommissionsAndFees();
        //eslint-disable-next-line
    }, []);

    const calculatedPrices: {
        depositFee: string;
        commissionPrice: string;
        total: string;
    } = useMemo(() => {
        var commissionRate = commissions?.find((x) => x.subCategoryId === selectedSubcategory?.subCatid)?.priceRate || 0;
        var exchangeRate = exchangeRates?.find((x) => x.name === priceCurrency)?.value || 1;
        var commission = (commissionRate / 100) * totalPrice * exchangeRate;
        var totalPriceTL = totalPrice * exchangeRate;
        var netPriceTL = totalPriceTL - commission;

        return {
            depositFee: (fees?.find((x) => x.subCategoryId === selectedSubcategory?.subCatid)?.depositFee || 0).toString() + " TL",
            commissionPrice: commission.toFixed(2).toString() + " TL",
            total: netPriceTL.toFixed(2).toString() + " TL",
        };
    }, [fees, commissions, selectedSubcategory, priceCurrency, totalPrice, exchangeRates]);

    const getMachine = async (machineId: number) => {
        const result = await doGetMachine(machineId);
        if (result?.documents) setDocs(result?.documents);

        editMachine(result);
    };

    const onUpdateSaleMachine = async (params: any) => {
        if (editedMachine && machineId) {
            const result = await doUpdateSaleMachine({
                ...editedMachine,
                ...params,
                documents: docs,
                isMachineForSale: true,
            }, Number.parseInt(machineId)
            );
            if (result) {
                editMachine(undefined);
                navigate("/sale/machines");
            }
        }
    };
    const onMachineFormFinish = async (params: any) => {
        let rentalTypes: MachineRentalTypeModel[] = [];

        editMachine((oldVal) => {
            return {
                ...oldVal,
                ...params,
                rentalTypes: rentalTypes,
                documents: docs,
            };
        });

        await onUpdateSaleMachine(params);
    };

    useEffect(() => {
        if (editedMachine && form) {
            form.setFieldsValue({ 
                ...editedMachine, 
                categoryName: editedMachine.subCategory?.category?.name,
            });
            setDefaultCurrencyId(editedMachine.machineSalePriceCurrencyId ?? 0);
            pageKey.current += 1;

            setTimeout(() => {
                setDataFetching(false);
            }, 2000);
        }

    }, [editedMachine, form]);

    const uploadedFilesChanged = (docs: DocumentModel[], category: DocumentCategory) => {
        setDocs((oldVal) => {
            var newCatDoc = oldVal?.filter((doc) => {
                return doc.documentCategory !== category;
            });
            if (!newCatDoc) newCatDoc = [];
            var addedDocs: MachineDocumentViewModel[] = docs.map((doc) => {
                return {
                    documentCategory: category,
                    document: doc,
                };
            });
            newCatDoc?.push(...addedDocs);

            return [...newCatDoc];
        });
    };

    const unApproveMachine = async () => {
        const result = await doUnApproveSaleMachine({ machineIds: [Number.parseInt(machineId ?? "")] });
        if (result) {
            navigate("/sale/machines");
        }
    };

    return (
        <ListContainer loading={dataFetching} title={t("machines.add.title")} description={t("machines.add.description")}>
            <Form name="add-machine" initialValues={editedMachine} form={form} onFinish={onMachineFormFinish} layout="vertical">
                <Row gutter={[4, 4]}>
                    <Col xs={24} md={12}>
                        <SaleMachineInfoPart
                            key={pageKey.current}
                            uploadChange={uploadedFilesChanged}
                            docs={pics}
                            form={form}
                            subcategoryChanged={(catid, subcatid) => setSelectedSubcategory({ catid: catid, subCatid: subcatid })}
                        />
                    </Col>

                    <Col xs={24} md={12}>
                        <Row gutter={[4, 4]}>
                            <Col md={24} xs={24}>
                                <SaleMachineAdditionalInfoPart key={pageKey.current} />
                            </Col>
                            <Col md={24} xs={24}>
                                <SaleMachinePricePart
                                    form={form}
                                    key={pageKey.current}
                                    defaultCurrencyId={defaultCurrencyId}
                                    onCurrencyChange={(val) => setPriceCurrency(val || "")}
                                    onPriceChange={(val) => setTotalPrice(val)}
                                    depositFee={calculatedPrices.depositFee}
                                    machineGoCommission={calculatedPrices.commissionPrice}
                                    totalPriceForMachineOwner={calculatedPrices.total}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24} md={24}>
                        <Row gutter={[16, 16]} justify="center">
                            {editedMachine?.isApprovedForSale && (
                                <Col md={6} xs={24}>
                                    <SecondaryButton loading={loading} onClick={unApproveMachine} style={{ marginTop: "1rem" }}>
                                        <Row gutter={[8, 0]} justify="center">
                                            <Col>
                                                <Delete />
                                            </Col>
                                            <Col>{t("machine.view.removeMachineFromPublishList")}</Col>
                                        </Row>
                                    </SecondaryButton>
                                </Col>
                            )}
                            <Col md={6} xs={24}>
                                <Form.Item>
                                    <PrimaryButton htmlType="submit" loading={loading} style={{ marginTop: "1rem" }}>
                                        {t("common.save")}
                                    </PrimaryButton>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </ListContainer>
    );
};

export default EditSaleMachine;
