/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MachineListModel
 */
export interface MachineListModel {
    /**
     * 
     * @type {number}
     * @memberof MachineListModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MachineListModel
     */
    category?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MachineListModel
     */
    categoryId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MachineListModel
     */
    subCategory?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MachineListModel
     */
    subCategoryId?: number;
    /**
     * 
     * @type {number}
     * @memberof MachineListModel
     */
    dailyPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineListModel
     */
    monthlyPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineListModel
     */
    price?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineListModel
     */
    photoId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MachineListModel
     */
    brandName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MachineListModel
     */
    brandOther?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MachineListModel
     */
    modelName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MachineListModel
     */
    modelYear?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MachineListModel
     */
    operatingWeight?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MachineListModel
     */
    operatingHeight?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MachineListModel
     */
    operationHour?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MachineListModel
     */
    operationMileage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MachineListModel
     */
    maxLoadCapacity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MachineListModel
     */
    loadCapacity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MachineListModel
     */
    maxHeight?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MachineListModel
     */
    nominalCapacity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MachineListModel
     */
    carryingCapacity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MachineListModel
     */
    enginePower?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MachineListModel
     */
    fuelType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MachineListModel
     */
    bomLength?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MachineListModel
     */
    maxPower?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MachineListModel
     */
    minPower?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MachineListModel
     */
    spraySpeed?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MachineListModel
     */
    tankVolume?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MachineListModel
     */
    volume?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MachineListModel
     */
    flightTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MachineListModel
     */
    cameraResolution?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MachineListModel
     */
    hasOperator?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MachineListModel
     */
    rank?: number | null;
}

export function MachineListModelFromJSON(json: any): MachineListModel {
    return MachineListModelFromJSONTyped(json, false);
}

export function MachineListModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineListModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        'subCategory': !exists(json, 'subCategory') ? undefined : json['subCategory'],
        'subCategoryId': !exists(json, 'subCategoryId') ? undefined : json['subCategoryId'],
        'dailyPrice': !exists(json, 'dailyPrice') ? undefined : json['dailyPrice'],
        'monthlyPrice': !exists(json, 'monthlyPrice') ? undefined : json['monthlyPrice'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'photoId': !exists(json, 'photoId') ? undefined : json['photoId'],
        'brandName': !exists(json, 'brandName') ? undefined : json['brandName'],
        'brandOther': !exists(json, 'brandOther') ? undefined : json['brandOther'],
        'modelName': !exists(json, 'modelName') ? undefined : json['modelName'],
        'modelYear': !exists(json, 'modelYear') ? undefined : json['modelYear'],
        'operatingWeight': !exists(json, 'operatingWeight') ? undefined : json['operatingWeight'],
        'operatingHeight': !exists(json, 'operatingHeight') ? undefined : json['operatingHeight'],
        'operationHour': !exists(json, 'operationHour') ? undefined : json['operationHour'],
        'operationMileage': !exists(json, 'operationMileage') ? undefined : json['operationMileage'],
        'maxLoadCapacity': !exists(json, 'maxLoadCapacity') ? undefined : json['maxLoadCapacity'],
        'loadCapacity': !exists(json, 'loadCapacity') ? undefined : json['loadCapacity'],
        'maxHeight': !exists(json, 'maxHeight') ? undefined : json['maxHeight'],
        'nominalCapacity': !exists(json, 'nominalCapacity') ? undefined : json['nominalCapacity'],
        'carryingCapacity': !exists(json, 'carryingCapacity') ? undefined : json['carryingCapacity'],
        'enginePower': !exists(json, 'enginePower') ? undefined : json['enginePower'],
        'fuelType': !exists(json, 'fuelType') ? undefined : json['fuelType'],
        'bomLength': !exists(json, 'bomLength') ? undefined : json['bomLength'],
        'maxPower': !exists(json, 'maxPower') ? undefined : json['maxPower'],
        'minPower': !exists(json, 'minPower') ? undefined : json['minPower'],
        'spraySpeed': !exists(json, 'spraySpeed') ? undefined : json['spraySpeed'],
        'tankVolume': !exists(json, 'tankVolume') ? undefined : json['tankVolume'],
        'volume': !exists(json, 'volume') ? undefined : json['volume'],
        'flightTime': !exists(json, 'flightTime') ? undefined : json['flightTime'],
        'cameraResolution': !exists(json, 'cameraResolution') ? undefined : json['cameraResolution'],
        'hasOperator': !exists(json, 'hasOperator') ? undefined : json['hasOperator'],
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
    };
}

export function MachineListModelToJSON(value?: MachineListModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'category': value.category,
        'categoryId': value.categoryId,
        'subCategory': value.subCategory,
        'subCategoryId': value.subCategoryId,
        'dailyPrice': value.dailyPrice,
        'monthlyPrice': value.monthlyPrice,
        'price': value.price,
        'photoId': value.photoId,
        'brandName': value.brandName,
        'brandOther': value.brandOther,
        'modelName': value.modelName,
        'modelYear': value.modelYear,
        'operatingWeight': value.operatingWeight,
        'operatingHeight': value.operatingHeight,
        'operationHour': value.operationHour,
        'operationMileage': value.operationMileage,
        'maxLoadCapacity': value.maxLoadCapacity,
        'loadCapacity': value.loadCapacity,
        'maxHeight': value.maxHeight,
        'nominalCapacity': value.nominalCapacity,
        'carryingCapacity': value.carryingCapacity,
        'enginePower': value.enginePower,
        'fuelType': value.fuelType,
        'bomLength': value.bomLength,
        'maxPower': value.maxPower,
        'minPower': value.minPower,
        'spraySpeed': value.spraySpeed,
        'tankVolume': value.tankVolume,
        'volume': value.volume,
        'flightTime': value.flightTime,
        'cameraResolution': value.cameraResolution,
        'hasOperator': value.hasOperator,
        'rank': value.rank,
    };
}

