import Login from "../pages";
import ForgotPassword from "../pages/forgot-password";
import Activate from "../pages/activate";
import ListForRentCommissionRates from "../pages/admin/commission-rates/list-for-rent-rates";
import React from "react";
import Machines from "../pages/admin";
import AddMachine from "../pages/admin/machines/add";
import ReviewMachine from "../pages/admin/machines/review";
import ViewMachine from "../pages/admin/machines/view";
import EditMachine from "../pages/admin/machines/edit";
import Requests from "../pages/admin/requests/requests";
import ViewRequest from "../pages/admin/requests/view";
import EditRequest from "../pages/admin/requests/edit";
import ViewOffer from "../pages/admin/offers/view";
import Offers from "../pages/admin/offers/offers";
import Renter from "../pages/admin/companies/renter";
import Lessor from "../pages/admin/companies/lessor";
import ViewRenter from "../pages/admin/companies/renter/view";
import ViewLessor from "../pages/admin/companies/lessor/view";
import Applicants from "../pages/admin/applicants";
import Notifications from "../pages/admin/notifications";
import PendingApproval from "../pages/admin/pending-approval";
import ApprovalDetail from "../pages/admin/pending-approval/detail";
import Showcases from "../pages/admin/showcases";
import ListForSaleCommissionRates from "../pages/admin/commission-rates/list-for-sale-rates";
import OnSaleMachines from "../pages/admin/on-sale-machines/list";
import AddSaleMachine from "../pages/admin/on-sale-machines/add";
import ViewSaleMachine from "../pages/admin/on-sale-machines/view";
import EditSaleMachine from "../pages/admin/on-sale-machines/edit";
import OnSalePendingApproval from "../pages/admin/on-sale-pending-approval";
import SaleShowcases from "../pages/admin/on-sale-showcases";
import ListForSaleDepositFee from "../pages/admin/commission-rates/list-for-fee";
import ReservedMachines from "../pages/admin/reserved-machines/list";
import OnSaleApprovalDetail from "../pages/admin/on-sale-pending-approval/detail";
import RemovedFromSaleMachines from "../pages/admin/on-sale-removed/list";
import BatchMachineUpdate from "../pages/admin/batch-machine-update/UpdateMachineInformationWithBatch";
import MachinesReports from "../pages/admin/reports/machines-reports";

type AdminPageType = {
    path:string,
    pathTitle:string,
    parents:{
        parent:string,
        parentTitle:string
    }[],
    element:React.ReactNode
    hidden?: boolean
}
export const MenuKeys:AdminPageType[] =
    [
        {
            path:"",
            pathTitle:"",
            parents:[],
            element: React.createElement(Login)
        },
        {
            path:"forgot-password",
            pathTitle:"",
            parents:[],
            element: React.createElement(ForgotPassword)
        },
        {
            path:"activation",
            pathTitle:"",
            parents:[],
            element: React.createElement(Activate)
        },
        {
            path:"reset-password",
            pathTitle:"",
            parents:[],
            element: React.createElement(Activate)
        },
        {
            path:"commission-rates",
            pathTitle:"",
            parents:[
                {
                    parent:"rent",
                    parentTitle:""
                }
            ],
            element: React.createElement(ListForRentCommissionRates)
        },
        {
            path:"add",
            pathTitle:"",
            parents:[
                {
                    parent:"rent",
                    parentTitle:""
                },
                {
                    parent:"machines",
                    parentTitle:""
                }
            ],
            element: React.createElement(AddMachine)
        },
        {
            path:"review",
            pathTitle:"",
            parents:[
                {
                    parent:"rent",
                    parentTitle:""
                },
                {
                    parent:"machines",
                    parentTitle:""
                }
            ],
            element: React.createElement(ReviewMachine)
        },
        {
            path:"view",
            pathTitle:"",
            parents:[
                {
                    parent:"rent",
                    parentTitle:""
                },
                {
                    parent:"machines",
                    parentTitle:""
                }
            ],
            element: React.createElement(ViewMachine)
        },
        {
            path:"machines",
            pathTitle:"",
            parents:[{
                parent:"rent",
                parentTitle:""
            }],
            element: React.createElement(Machines)
        },
        {
            path:"edit",
            pathTitle:"",
            parents:[
                {
                    parent:"rent",
                    parentTitle:""
                },
                {
                    parent:"machines",
                    parentTitle:""
                }
            ],
            element: React.createElement(EditMachine)
        },
        {
            path:"pending-approval",
            pathTitle:"",
            parents:[{
                parent:"rent",
                parentTitle:""
            }],
            element: React.createElement(PendingApproval)
        },
        {
            path:"edit",
            pathTitle:"",
            parents:[{
                parent:"rent",
                parentTitle:""
            },{
                parent:"pending-approval",
                parentTitle:""
            }],
            element: React.createElement(EditMachine)
        },
        {
            path:"showcases",
            pathTitle:"",
            parents:[{
                parent:"rent",
                parentTitle:""
            }],
            element: React.createElement(Showcases)
        },
        {
            path:"detail",
            pathTitle:"",
            parents:[
                {
                    parent:"rent",
                    parentTitle:""
                },
                {
                    parent:"pending-approval",
                    parentTitle:""
                }
            ],
            element: React.createElement(ApprovalDetail)
        },
        {
            path:"requests",
            pathTitle:"",
            parents:[
                {
                    parent:"rent",
                    parentTitle:""
                }
            ],
            element: React.createElement(Requests)
        },
        {
            path:"view",
            pathTitle:"",
            parents:[
                {
                    parent:"rent",
                    parentTitle:""
                },
                {
                    parent:"requests",
                    parentTitle:""
                }
            ],
            element: React.createElement(ViewRequest)
        },
        {
            path:"edit",
            pathTitle:"",
            parents:[
                {
                    parent:"rent",
                    parentTitle:""
                },
                {
                    parent:"requests",
                    parentTitle:""
                }
            ],
            element: React.createElement(EditRequest)
        },
        {
            path:"offers",
            pathTitle:"",
            parents:[
                {
                    parent:"rent",
                    parentTitle:""
                }
            ],
            element: React.createElement(Offers)
        },
        {
            path:"view",
            pathTitle:"",
            parents:[
                {
                    parent:"rent",
                    parentTitle:""
                },
                {
                    parent:"offers",
                    parentTitle:""
                }
            ],
            element: React.createElement(ViewOffer)
        },
        {
            path:"commission-rates",
            pathTitle:"",
            parents:[
                {
                    parent:"sale",
                    parentTitle:""
                }
            ],
            hidden: true,
            element: React.createElement(ListForSaleCommissionRates)
        },
        {
            path:"machines",
            pathTitle:"",
            parents:[{
                parent:"sale",
                parentTitle:""
            }],
            element: React.createElement(OnSaleMachines)
        },
        {
            path:"add",
            pathTitle:"",
            parents:[
                {
                    parent:"sale",
                    parentTitle:""
                },
                {
                    parent:"machines",
                    parentTitle:""
                }
            ],
            element: React.createElement(AddSaleMachine)
        },
        {
            path:"view",
            pathTitle:"",
            parents:[
                {
                    parent:"sale",
                    parentTitle:""
                },
                {
                    parent:"machines",
                    parentTitle:""
                }
            ],
            element: React.createElement(ViewSaleMachine)
        },
        {
            path:"edit",
            pathTitle:"",
            parents:[
                {
                    parent:"sale",
                    parentTitle:""
                },
                {
                    parent:"machines",
                    parentTitle:""
                }
            ],
            element: React.createElement(EditSaleMachine)
        },
        {
            path:"pending-approval",
            pathTitle:"",
            parents:[{
                parent:"sale",
                parentTitle:""
            }],
            element: React.createElement(OnSalePendingApproval)
        },
        {
            path:"edit",
            pathTitle:"",
            parents:[{
                parent:"sale",
                parentTitle:""
            },{
                parent:"pending-approval",
                parentTitle:""
            }],
            element: React.createElement(EditSaleMachine)
        },
        {
            path:"removed",
            pathTitle:"",
            parents:[{
                parent:"sale",
                parentTitle:""
            }],
            element: React.createElement(RemovedFromSaleMachines)
        },
        {
            path:"view",
            pathTitle:"",
            parents:[
                {
                    parent:"sale",
                    parentTitle:""
                },
                {
                    parent:"removed",
                    parentTitle:""
                }
            ],
            element: React.createElement(ViewSaleMachine)
        },
        {
            path:"showcases",
            pathTitle:"",
            parents:[{
                parent:"sale",
                parentTitle:""
            }],
            element: React.createElement(SaleShowcases)
        },
        {
            path:"detail",
            pathTitle:"",
            parents:[
                {
                    parent:"sale",
                    parentTitle:""
                },
                {
                    parent:"pending-approval",
                    parentTitle:""
                }
            ],
            element: React.createElement(OnSaleApprovalDetail)
        },
        {
            path:"deposit-fee",
            pathTitle:"",
            parents:[
                {
                    parent:"sale",
                    parentTitle:""
                }
            ],
            hidden: true,
            element: React.createElement(ListForSaleDepositFee)
        },
        {
            path:"reserved-machines",
            pathTitle:"",
            parents:[
                {
                    parent:"sale",
                    parentTitle:""
                }
            ],
            hidden: true,
            element: React.createElement(ReservedMachines)
        },
        {
            path:"renter-company",
            pathTitle:"",
            parents:[],
            element: React.createElement(Renter)
        },
        {
            path:"lessor-company",
            pathTitle:"",
            parents:[],
            element: React.createElement(Lessor)
        },
        {
            path:"view",
            pathTitle:"",
            parents:[
                {
                    parent:"renter-company",
                    parentTitle:""
                }
            ],
            element: React.createElement(ViewRenter)
        },
        {
            path:"view",
            pathTitle:"",
            parents:[
                {
                    parent:"lessor-company",
                    parentTitle:""
                }
            ],
            element: React.createElement(ViewLessor)
        },
        {
            path:"applicants",
            pathTitle:"",
            parents:[],
            element: React.createElement(Applicants)
        },
        {
            path:"notifications",
            pathTitle:"",
            parents:[],
            element: React.createElement(Notifications)
        },
        {
            path:"batch-machine-update",
            pathTitle:"",
            parents:[],
            element: React.createElement(BatchMachineUpdate)
        },
        {
            path:"reports",
            pathTitle:"",
            parents:[],
            element: React.createElement(MachinesReports)
        }
    ];