import { Col, Row } from "antd";
import BasicModelViewer from "../components/atomics/basic-model-viewer";
import { t } from "../translate";
import { ViewPartProps } from "../viewmodels/view-page-part-props";
import BorderlessContainer from "../components/containers/borderless-container";

const MachineSaleAdditionalInfoPart = (props: ViewPartProps) => {
    const notes = props.machine?.saleNotes ?? "";
    return (
        <BorderlessContainer title={t("machines.add.additionalInfoTitle")}>
            <Row gutter={[16, 16]}>
                <Col xs={24} style={{ wordWrap: "break-word" }}>
                    <BasicModelViewer xs={24} md={24} objKey={t("common.description")} value={notes} />
                </Col>
            </Row>
        </BorderlessContainer>
    );
};

export default MachineSaleAdditionalInfoPart;
