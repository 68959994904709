/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ActivationModel,
    ActivationModelFromJSON,
    ActivationModelToJSON,
    BasicModel,
    BasicModelFromJSON,
    BasicModelToJSON,
    BlockStatusViewModel,
    BlockStatusViewModelFromJSON,
    BlockStatusViewModelToJSON,
    ErrorModel,
    ErrorModelFromJSON,
    ErrorModelToJSON,
    ForgotPasswordViewModel,
    ForgotPasswordViewModelFromJSON,
    ForgotPasswordViewModelToJSON,
    Int32ValidatedModel,
    Int32ValidatedModelFromJSON,
    Int32ValidatedModelToJSON,
    LessorCompanyAgreementsViewModel,
    LessorCompanyAgreementsViewModelFromJSON,
    LessorCompanyAgreementsViewModelToJSON,
    LessorCompanyAuthoriseLessorViewModel,
    LessorCompanyAuthoriseLessorViewModelFromJSON,
    LessorCompanyAuthoriseLessorViewModelToJSON,
    LessorCompanyAuthorizedSellerInfoModel,
    LessorCompanyAuthorizedSellerInfoModelFromJSON,
    LessorCompanyAuthorizedSellerInfoModelToJSON,
    LessorCompanyBasicInfoForMachineViewModel,
    LessorCompanyBasicInfoForMachineViewModelFromJSON,
    LessorCompanyBasicInfoForMachineViewModelToJSON,
    LessorCompanyChangePasswordViewModel,
    LessorCompanyChangePasswordViewModelFromJSON,
    LessorCompanyChangePasswordViewModelToJSON,
    LessorCompanyCreateModel,
    LessorCompanyCreateModelFromJSON,
    LessorCompanyCreateModelToJSON,
    LessorCompanyDetailViewModel,
    LessorCompanyDetailViewModelFromJSON,
    LessorCompanyDetailViewModelToJSON,
    LessorCompanyInfoModel,
    LessorCompanyInfoModelFromJSON,
    LessorCompanyInfoModelToJSON,
    LessorCompanyInfoUpdateModel,
    LessorCompanyInfoUpdateModelFromJSON,
    LessorCompanyInfoUpdateModelToJSON,
    LessorCompanyPaymentInfoViewModel,
    LessorCompanyPaymentInfoViewModelFromJSON,
    LessorCompanyPaymentInfoViewModelToJSON,
    LessorCompanyProfileCreateModel,
    LessorCompanyProfileCreateModelFromJSON,
    LessorCompanyProfileCreateModelToJSON,
    LessorCompanyProfileViewModel,
    LessorCompanyProfileViewModelFromJSON,
    LessorCompanyProfileViewModelToJSON,
    LessorCompanyRequestViewModel,
    LessorCompanyRequestViewModelFromJSON,
    LessorCompanyRequestViewModelToJSON,
    LessorCompanySearchModel,
    LessorCompanySearchModelFromJSON,
    LessorCompanySearchModelToJSON,
    LessorCompanyViewModelPagedDataModel,
    LessorCompanyViewModelPagedDataModelFromJSON,
    LessorCompanyViewModelPagedDataModelToJSON,
    LessorUserCreateViewModel,
    LessorUserCreateViewModelFromJSON,
    LessorUserCreateViewModelToJSON,
    LessorUserViewModel,
    LessorUserViewModelFromJSON,
    LessorUserViewModelToJSON,
    LoginRequestModel,
    LoginRequestModelFromJSON,
    LoginRequestModelToJSON,
    LoginResponseModel,
    LoginResponseModelFromJSON,
    LoginResponseModelToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    ResetPasswordViewModel,
    ResetPasswordViewModelFromJSON,
    ResetPasswordViewModelToJSON,
} from '../models';

export interface LessorCompanyActivatePostRequest {
    activationModel?: ActivationModel;
}

export interface LessorCompanyAuthoriseLessorPostRequest {
    lessorCompanyAuthoriseLessorViewModel?: LessorCompanyAuthoriseLessorViewModel;
}

export interface LessorCompanyAuthorisedLessorsMachineIdGetRequest {
    machineId: number;
}

export interface LessorCompanyChangeBlockStatusUserIdGetRequest {
    userId: number;
}

export interface LessorCompanyChangePasswordPostRequest {
    lessorCompanyChangePasswordViewModel?: LessorCompanyChangePasswordViewModel;
}

export interface LessorCompanyCreateUserPostRequest {
    lessorUserCreateViewModel?: LessorUserCreateViewModel;
}

export interface LessorCompanyDeleteUserUserIdGetRequest {
    userId: number;
}

export interface LessorCompanyForgotPasswordPostRequest {
    forgotPasswordViewModel?: ForgotPasswordViewModel;
}

export interface LessorCompanyGetBlockStatusUserIdGetRequest {
    userId: number;
}

export interface LessorCompanyGetSuitableCompaniesForRequestRequestIdGetRequest {
    requestId: number;
}

export interface LessorCompanyIdGetRequest {
    id: number;
}

export interface LessorCompanyIdOtpDeleteRequest {
    id: number;
    otp: string;
}

export interface LessorCompanyInfoIdGetRequest {
    id: number;
}

export interface LessorCompanyInfoPostRequest {
    lessorCompanyInfoUpdateModel?: LessorCompanyInfoUpdateModel;
}

export interface LessorCompanyListUsersCompanyIdGetRequest {
    companyId: number;
}

export interface LessorCompanyMachineOwnerByMachineIdMachineIdGetRequest {
    machineId: number;
}

export interface LessorCompanyMachineSellersContactMachineIdGetRequest {
    machineId: number;
}

export interface LessorCompanyProfilePostRequest {
    lessorCompanyProfileCreateModel?: LessorCompanyProfileCreateModel;
}

export interface LessorCompanyResendActivationCodeUserIdGetRequest {
    userId: number;
}

export interface LessorCompanyResetPasswordPostRequest {
    resetPasswordViewModel?: ResetPasswordViewModel;
}

export interface LessorCompanySearchPostRequest {
    lessorCompanySearchModel?: LessorCompanySearchModel;
}

export interface LessorCompanySignInPostRequest {
    loginRequestModel?: LoginRequestModel;
}

export interface LessorCompanySignUpPostRequest {
    lessorCompanyCreateModel?: LessorCompanyCreateModel;
}

export interface LessorCompanyUpdateAgreementsVersionsPostRequest {
    lessorCompanyAgreementsViewModel?: LessorCompanyAgreementsViewModel;
}

export interface LessorCompanyUpdatePaymentInfoPostRequest {
    lessorCompanyPaymentInfoViewModel?: LessorCompanyPaymentInfoViewModel;
}

/**
 * 
 */
export class LessorCompanyApi extends runtime.BaseAPI {

    /**
     * Captcha ( LessorActivate = lessoractivate )
     */
    async lessorCompanyActivatePostRaw(requestParameters: LessorCompanyActivatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ActivationModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/activate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ActivationModelToJSON(requestParameters.activationModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivationModelFromJSON(jsonValue));
    }

    /**
     * Captcha ( LessorActivate = lessoractivate )
     */
    async lessorCompanyActivatePost(requestParameters: LessorCompanyActivatePostRequest = {}, initOverrides?: RequestInit): Promise<ActivationModel> {
        const response = await this.lessorCompanyActivatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lessor, Admin
     */
    async lessorCompanyAuthoriseLessorPostRaw(requestParameters: LessorCompanyAuthoriseLessorPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/authorise-lessor`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LessorCompanyAuthoriseLessorViewModelToJSON(requestParameters.lessorCompanyAuthoriseLessorViewModel),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Lessor, Admin
     */
    async lessorCompanyAuthoriseLessorPost(requestParameters: LessorCompanyAuthoriseLessorPostRequest = {}, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.lessorCompanyAuthoriseLessorPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lessorCompanyAuthorisedLessorsMachineIdGetRaw(requestParameters: LessorCompanyAuthorisedLessorsMachineIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling lessorCompanyAuthorisedLessorsMachineIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/authorised-lessors/{machineId}`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async lessorCompanyAuthorisedLessorsMachineIdGet(requestParameters: LessorCompanyAuthorisedLessorsMachineIdGetRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.lessorCompanyAuthorisedLessorsMachineIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Lessor
     */
    async lessorCompanyChangeBlockStatusUserIdGetRaw(requestParameters: LessorCompanyChangeBlockStatusUserIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BlockStatusViewModel>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling lessorCompanyChangeBlockStatusUserIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/change-block-status/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BlockStatusViewModelFromJSON(jsonValue));
    }

    /**
     * Admin, Lessor
     */
    async lessorCompanyChangeBlockStatusUserIdGet(requestParameters: LessorCompanyChangeBlockStatusUserIdGetRequest, initOverrides?: RequestInit): Promise<BlockStatusViewModel> {
        const response = await this.lessorCompanyChangeBlockStatusUserIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lessor
     */
    async lessorCompanyChangePasswordPostRaw(requestParameters: LessorCompanyChangePasswordPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/change-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LessorCompanyChangePasswordViewModelToJSON(requestParameters.lessorCompanyChangePasswordViewModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Lessor
     */
    async lessorCompanyChangePasswordPost(requestParameters: LessorCompanyChangePasswordPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.lessorCompanyChangePasswordPostRaw(requestParameters, initOverrides);
    }

    /**
     * Admin, Lessor
     */
    async lessorCompanyCreateUserPostRaw(requestParameters: LessorCompanyCreateUserPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LessorUserCreateViewModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/create-user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LessorUserCreateViewModelToJSON(requestParameters.lessorUserCreateViewModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LessorUserCreateViewModelFromJSON(jsonValue));
    }

    /**
     * Admin, Lessor
     */
    async lessorCompanyCreateUserPost(requestParameters: LessorCompanyCreateUserPostRequest = {}, initOverrides?: RequestInit): Promise<LessorUserCreateViewModel> {
        const response = await this.lessorCompanyCreateUserPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Captcha ( LessorForgotPassword = lessorforgotpassword )
     */
    async lessorCompanyDeleteAccountOtpPostRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Int32ValidatedModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/delete-account-otp`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => Int32ValidatedModelFromJSON(jsonValue));
    }

    /**
     * Captcha ( LessorForgotPassword = lessorforgotpassword )
     */
    async lessorCompanyDeleteAccountOtpPost(initOverrides?: RequestInit): Promise<Int32ValidatedModel> {
        const response = await this.lessorCompanyDeleteAccountOtpPostRaw(initOverrides);
        return await response.value();
    }

    /**
     * Admin, Lessor
     */
    async lessorCompanyDeleteUserUserIdGetRaw(requestParameters: LessorCompanyDeleteUserUserIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LessorUserViewModel>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling lessorCompanyDeleteUserUserIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/delete-user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LessorUserViewModelFromJSON(jsonValue));
    }

    /**
     * Admin, Lessor
     */
    async lessorCompanyDeleteUserUserIdGet(requestParameters: LessorCompanyDeleteUserUserIdGetRequest, initOverrides?: RequestInit): Promise<LessorUserViewModel> {
        const response = await this.lessorCompanyDeleteUserUserIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Captcha ( LessorForgotPassword = lessorforgotpassword )
     */
    async lessorCompanyForgotPasswordPostRaw(requestParameters: LessorCompanyForgotPasswordPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Int32ValidatedModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/forgot-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ForgotPasswordViewModelToJSON(requestParameters.forgotPasswordViewModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => Int32ValidatedModelFromJSON(jsonValue));
    }

    /**
     * Captcha ( LessorForgotPassword = lessorforgotpassword )
     */
    async lessorCompanyForgotPasswordPost(requestParameters: LessorCompanyForgotPasswordPostRequest = {}, initOverrides?: RequestInit): Promise<Int32ValidatedModel> {
        const response = await this.lessorCompanyForgotPasswordPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Lessor
     */
    async lessorCompanyGetBlockStatusUserIdGetRaw(requestParameters: LessorCompanyGetBlockStatusUserIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BlockStatusViewModel>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling lessorCompanyGetBlockStatusUserIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/get-block-status/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BlockStatusViewModelFromJSON(jsonValue));
    }

    /**
     * Admin, Lessor
     */
    async lessorCompanyGetBlockStatusUserIdGet(requestParameters: LessorCompanyGetBlockStatusUserIdGetRequest, initOverrides?: RequestInit): Promise<BlockStatusViewModel> {
        const response = await this.lessorCompanyGetBlockStatusUserIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async lessorCompanyGetSuitableCompaniesForRequestRequestIdGetRaw(requestParameters: LessorCompanyGetSuitableCompaniesForRequestRequestIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<LessorCompanyRequestViewModel>>> {
        if (requestParameters.requestId === null || requestParameters.requestId === undefined) {
            throw new runtime.RequiredError('requestId','Required parameter requestParameters.requestId was null or undefined when calling lessorCompanyGetSuitableCompaniesForRequestRequestIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/get-suitable-companies-for-request/{requestId}`.replace(`{${"requestId"}}`, encodeURIComponent(String(requestParameters.requestId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LessorCompanyRequestViewModelFromJSON));
    }

    /**
     * Admin
     */
    async lessorCompanyGetSuitableCompaniesForRequestRequestIdGet(requestParameters: LessorCompanyGetSuitableCompaniesForRequestRequestIdGetRequest, initOverrides?: RequestInit): Promise<Array<LessorCompanyRequestViewModel>> {
        const response = await this.lessorCompanyGetSuitableCompaniesForRequestRequestIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Lessor
     */
    async lessorCompanyIdGetRaw(requestParameters: LessorCompanyIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LessorCompanyDetailViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling lessorCompanyIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LessorCompanyDetailViewModelFromJSON(jsonValue));
    }

    /**
     * Admin, Lessor
     */
    async lessorCompanyIdGet(requestParameters: LessorCompanyIdGetRequest, initOverrides?: RequestInit): Promise<LessorCompanyDetailViewModel> {
        const response = await this.lessorCompanyIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lessor, Admin
     */
    async lessorCompanyIdOtpDeleteRaw(requestParameters: LessorCompanyIdOtpDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling lessorCompanyIdOtpDelete.');
        }

        if (requestParameters.otp === null || requestParameters.otp === undefined) {
            throw new runtime.RequiredError('otp','Required parameter requestParameters.otp was null or undefined when calling lessorCompanyIdOtpDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/{id}/{otp}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"otp"}}`, encodeURIComponent(String(requestParameters.otp))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Lessor, Admin
     */
    async lessorCompanyIdOtpDelete(requestParameters: LessorCompanyIdOtpDeleteRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.lessorCompanyIdOtpDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lessor
     */
    async lessorCompanyInfoGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<LessorCompanyInfoModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LessorCompanyInfoModelFromJSON(jsonValue));
    }

    /**
     * Lessor
     */
    async lessorCompanyInfoGet(initOverrides?: RequestInit): Promise<LessorCompanyInfoModel> {
        const response = await this.lessorCompanyInfoGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async lessorCompanyInfoIdGetRaw(requestParameters: LessorCompanyInfoIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LessorCompanyInfoModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling lessorCompanyInfoIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/info/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LessorCompanyInfoModelFromJSON(jsonValue));
    }

    /**
     * Admin
     */
    async lessorCompanyInfoIdGet(requestParameters: LessorCompanyInfoIdGetRequest, initOverrides?: RequestInit): Promise<LessorCompanyInfoModel> {
        const response = await this.lessorCompanyInfoIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lessor
     */
    async lessorCompanyInfoPostRaw(requestParameters: LessorCompanyInfoPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LessorCompanyInfoUpdateModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/info`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LessorCompanyInfoUpdateModelToJSON(requestParameters.lessorCompanyInfoUpdateModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LessorCompanyInfoUpdateModelFromJSON(jsonValue));
    }

    /**
     * Lessor
     */
    async lessorCompanyInfoPost(requestParameters: LessorCompanyInfoPostRequest = {}, initOverrides?: RequestInit): Promise<LessorCompanyInfoUpdateModel> {
        const response = await this.lessorCompanyInfoPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Lessor
     */
    async lessorCompanyListUsersCompanyIdGetRaw(requestParameters: LessorCompanyListUsersCompanyIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<LessorUserViewModel>>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling lessorCompanyListUsersCompanyIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/list-users/{companyId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LessorUserViewModelFromJSON));
    }

    /**
     * Admin, Lessor
     */
    async lessorCompanyListUsersCompanyIdGet(requestParameters: LessorCompanyListUsersCompanyIdGetRequest, initOverrides?: RequestInit): Promise<Array<LessorUserViewModel>> {
        const response = await this.lessorCompanyListUsersCompanyIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Captcha ( LessorUserByMachineId = lessoruserbymachineid )
     */
    async lessorCompanyMachineOwnerByMachineIdMachineIdGetRaw(requestParameters: LessorCompanyMachineOwnerByMachineIdMachineIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LessorCompanyBasicInfoForMachineViewModel>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling lessorCompanyMachineOwnerByMachineIdMachineIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/machine-owner-by-machine-id/{machineId}`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LessorCompanyBasicInfoForMachineViewModelFromJSON(jsonValue));
    }

    /**
     * Captcha ( LessorUserByMachineId = lessoruserbymachineid )
     */
    async lessorCompanyMachineOwnerByMachineIdMachineIdGet(requestParameters: LessorCompanyMachineOwnerByMachineIdMachineIdGetRequest, initOverrides?: RequestInit): Promise<LessorCompanyBasicInfoForMachineViewModel> {
        const response = await this.lessorCompanyMachineOwnerByMachineIdMachineIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Captcha ( LessorUserByMachineId = lessoruserbymachineid )
     */
    async lessorCompanyMachineSellersContactMachineIdGetRaw(requestParameters: LessorCompanyMachineSellersContactMachineIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LessorCompanyAuthorizedSellerInfoModel>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling lessorCompanyMachineSellersContactMachineIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/machine-sellers-contact/{machineId}`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LessorCompanyAuthorizedSellerInfoModelFromJSON(jsonValue));
    }

    /**
     * Captcha ( LessorUserByMachineId = lessoruserbymachineid )
     */
    async lessorCompanyMachineSellersContactMachineIdGet(requestParameters: LessorCompanyMachineSellersContactMachineIdGetRequest, initOverrides?: RequestInit): Promise<LessorCompanyAuthorizedSellerInfoModel> {
        const response = await this.lessorCompanyMachineSellersContactMachineIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lessor
     */
    async lessorCompanyProfileGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<LessorCompanyProfileViewModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LessorCompanyProfileViewModelFromJSON(jsonValue));
    }

    /**
     * Lessor
     */
    async lessorCompanyProfileGet(initOverrides?: RequestInit): Promise<LessorCompanyProfileViewModel> {
        const response = await this.lessorCompanyProfileGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Lessor
     */
    async lessorCompanyProfilePostRaw(requestParameters: LessorCompanyProfilePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LessorCompanyProfileCreateModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/profile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LessorCompanyProfileCreateModelToJSON(requestParameters.lessorCompanyProfileCreateModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LessorCompanyProfileCreateModelFromJSON(jsonValue));
    }

    /**
     * Lessor
     */
    async lessorCompanyProfilePost(requestParameters: LessorCompanyProfilePostRequest = {}, initOverrides?: RequestInit): Promise<LessorCompanyProfileCreateModel> {
        const response = await this.lessorCompanyProfilePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Captcha ( LessorResendActivate = lessorresendactivate )
     */
    async lessorCompanyResendActivationCodeUserIdGetRaw(requestParameters: LessorCompanyResendActivationCodeUserIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling lessorCompanyResendActivationCodeUserIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/resend-activation-code/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Captcha ( LessorResendActivate = lessorresendactivate )
     */
    async lessorCompanyResendActivationCodeUserIdGet(requestParameters: LessorCompanyResendActivationCodeUserIdGetRequest, initOverrides?: RequestInit): Promise<void> {
        await this.lessorCompanyResendActivationCodeUserIdGetRaw(requestParameters, initOverrides);
    }

    /**
     * Captcha ( LessorResetPassword = lessorresetpassword )
     */
    async lessorCompanyResetPasswordPostRaw(requestParameters: LessorCompanyResetPasswordPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordViewModelToJSON(requestParameters.resetPasswordViewModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Captcha ( LessorResetPassword = lessorresetpassword )
     */
    async lessorCompanyResetPasswordPost(requestParameters: LessorCompanyResetPasswordPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.lessorCompanyResetPasswordPostRaw(requestParameters, initOverrides);
    }

    /**
     * Admin
     */
    async lessorCompanySearchPostRaw(requestParameters: LessorCompanySearchPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LessorCompanyViewModelPagedDataModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LessorCompanySearchModelToJSON(requestParameters.lessorCompanySearchModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LessorCompanyViewModelPagedDataModelFromJSON(jsonValue));
    }

    /**
     * Admin
     */
    async lessorCompanySearchPost(requestParameters: LessorCompanySearchPostRequest = {}, initOverrides?: RequestInit): Promise<LessorCompanyViewModelPagedDataModel> {
        const response = await this.lessorCompanySearchPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lessor
     */
    async lessorCompanySendOtpGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/send-otp`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Lessor
     */
    async lessorCompanySendOtpGet(initOverrides?: RequestInit): Promise<void> {
        await this.lessorCompanySendOtpGetRaw(initOverrides);
    }

    /**
     * Admin
     */
    async lessorCompanyShortListGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<BasicModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/short-list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BasicModelFromJSON));
    }

    /**
     * Admin
     */
    async lessorCompanyShortListGet(initOverrides?: RequestInit): Promise<Array<BasicModel>> {
        const response = await this.lessorCompanyShortListGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Captcha ( LessorSignIn = lessorsignin )
     */
    async lessorCompanySignInPostRaw(requestParameters: LessorCompanySignInPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoginResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/sign-in`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginRequestModelToJSON(requestParameters.loginRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseModelFromJSON(jsonValue));
    }

    /**
     * Captcha ( LessorSignIn = lessorsignin )
     */
    async lessorCompanySignInPost(requestParameters: LessorCompanySignInPostRequest = {}, initOverrides?: RequestInit): Promise<LoginResponseModel> {
        const response = await this.lessorCompanySignInPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lessor
     */
    async lessorCompanySignOutGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/sign-out`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Lessor
     */
    async lessorCompanySignOutGet(initOverrides?: RequestInit): Promise<void> {
        await this.lessorCompanySignOutGetRaw(initOverrides);
    }

    /**
     * Captcha ( LessorSignUp = lessorsignup )
     */
    async lessorCompanySignUpPostRaw(requestParameters: LessorCompanySignUpPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Int32ValidatedModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/sign-up`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LessorCompanyCreateModelToJSON(requestParameters.lessorCompanyCreateModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => Int32ValidatedModelFromJSON(jsonValue));
    }

    /**
     * Captcha ( LessorSignUp = lessorsignup )
     */
    async lessorCompanySignUpPost(requestParameters: LessorCompanySignUpPostRequest = {}, initOverrides?: RequestInit): Promise<Int32ValidatedModel> {
        const response = await this.lessorCompanySignUpPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lessor
     */
    async lessorCompanySwitchToRenterDashboardGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoginResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/switch-to-renter-dashboard`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseModelFromJSON(jsonValue));
    }

    /**
     * Lessor
     */
    async lessorCompanySwitchToRenterDashboardGet(initOverrides?: RequestInit): Promise<LoginResponseModel> {
        const response = await this.lessorCompanySwitchToRenterDashboardGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Lessor
     */
    async lessorCompanyUpdateAgreementsVersionsPostRaw(requestParameters: LessorCompanyUpdateAgreementsVersionsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/update-agreements-versions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LessorCompanyAgreementsViewModelToJSON(requestParameters.lessorCompanyAgreementsViewModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Lessor
     */
    async lessorCompanyUpdateAgreementsVersionsPost(requestParameters: LessorCompanyUpdateAgreementsVersionsPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.lessorCompanyUpdateAgreementsVersionsPostRaw(requestParameters, initOverrides);
    }

    /**
     * Lessor, Admin
     */
    async lessorCompanyUpdatePaymentInfoPostRaw(requestParameters: LessorCompanyUpdatePaymentInfoPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/update-payment-info`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LessorCompanyPaymentInfoViewModelToJSON(requestParameters.lessorCompanyPaymentInfoViewModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Lessor, Admin
     */
    async lessorCompanyUpdatePaymentInfoPost(requestParameters: LessorCompanyUpdatePaymentInfoPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.lessorCompanyUpdatePaymentInfoPostRaw(requestParameters, initOverrides);
    }

    /**
     * Lessor
     */
    async lessorCompanyUserDetailGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoginResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/lessor-company/user-detail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseModelFromJSON(jsonValue));
    }

    /**
     * Lessor
     */
    async lessorCompanyUserDetailGet(initOverrides?: RequestInit): Promise<LoginResponseModel> {
        const response = await this.lessorCompanyUserDetailGetRaw(initOverrides);
        return await response.value();
    }

}
