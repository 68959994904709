import { Button, Card, Col, Modal, Row, Space, Table } from "antd";
import { useEffect, useRef, useState } from "react";
import BoxContainer from "../../../components/containers/box-container";
import ListContainer from "../../../components/containers/list-container";
import { t } from "../../../translate";
import * as XLSX from "xlsx";
import Title from "antd/lib/typography/Title";
import { WarningFilled, CloseCircleOutlined, CheckCircleFilled, LoadingOutlined } from "@ant-design/icons";
import "../batch-machine-update/UpdateMachineInformationWithBatch.css";
import { useSecureService } from "../../../store/service-atoms";
import {
    BatchMachineInfoUpdate,
    BatchMachineInfoUpdateListValidatedModel,
    MachineRentalTypeCreateModel,
    ReportMachinesResponseViewModel,
} from "../../../service";
import { ColumnProps } from "antd/lib/table";
import PrimaryButton from "../../../components/atomics/buttons/primary-button";

export type tableColumns = {
    title: string;
    dataIndex: string;
    key: string;
    width: number;
};

export type PostRentalMachine = {
    machineId: number;
    rentalTypes: rentalTypes[] | undefined;
};

export type rentalTypes = {
    RentalTypeId: number;
    CurrencyId: number;
    Price: number;
};

export type message = {
    message: string | undefined;
    color: string | undefined;
};
const BatchMachineUpdate = () => {
    const [fileName, setFileName] = useState(null);
    const [columns, setColumns]: any = useState();
    const [table, setTable]: any = useState([]);
    const [outGoingUpdateMachines, SetOutGoingUpdateMachines] = useState<BatchMachineInfoUpdate[]>([]);
    const [uploadedExcelData, setUploadedExcelData] = useState<ReportMachinesResponseViewModel[]>();
    const fileInput: any = useRef(null);
    const [loading, setLoading]: any = useState(false);
    const [isSuccess, setIsSuccess]: any = useState(false);
    const [disableUpdateButton, setDisableUpdateButton]: any = useState(false);

    const [infoMessage, setInfoMessage] = useState<message>();
    const [changedMachineNumber, setChangedMachineNumber] = useState<number | undefined>(undefined);
    const { doBatchMachineCompare, doBatchMachineUpdate } = useSecureService();

    useEffect(() => {}, [table]);

    const handleFileUpload = async (e: any) => {
        setUploadedExcelData([]);
        setTable([]);
        setChangedMachineNumber(undefined);
        setLoading(true);
        const file = e.target.files[0];
        if (file) {
            const data = await file.arrayBuffer();
            const workbook = XLSX.read(data);
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            let jsonData = XLSX.utils.sheet_to_json(worksheet);
            const tableColumns: ColumnProps<BatchMachineInfoUpdate>[] = [
                {
                    title: "Şirket ID",

                    dataIndex: "lessorCompanyId",

                    key: "LessorCompanyId",

                    width: 150,
                    sorter: (a: any, b: any) => (a.lessorCompanyId ? 1 : -1),
                },

                {
                    title: "Şirket Adı",

                    dataIndex: "lessorCompaniesName",

                    key: "LessorCompaniesName",

                    width: 150,
                    sorter: (a: any, b: any) => a.lessorCompaniesName.localeCompare(b.lessorCompaniesName),
                },

                {
                    title: "Kontak Ad Soyad",

                    dataIndex: "lessorCompanyContactName",

                    key: "LessorCompanyContactName",

                    width: 150,
                    sorter: (a: any, b: any) => a.lessorCompanyContactName.localeCompare(b.lessorCompanyContactName),
                },
                {
                    title: "Kontak Telefon No",

                    dataIndex: "lessorCompanyPhone",

                    key: "LessorCompanyPhone",

                    width: 150,
                },
                {
                    title: "Kontak e-posta",

                    dataIndex: "lessorCompanyEmail",

                    key: "LessorCompanyEmail",

                    width: 150,
                    sorter: (a: any, b: any) => a.lessorCompanyEmail.localeCompare(b.lessorCompanyEmail),
                },
                {
                    title: "Kayıtlı Makine Sayısı",

                    dataIndex: "lessorCompanyRegisteredMachineCount",

                    key: "lessorCompanyRegisteredMachineCount",

                    width: 150,
                },
                {
                    title: "Hizmet Bölgeleri",

                    dataIndex: "lessorCompanyLocation",

                    key: "LessorCompanyLocation",

                    width: 150,
                },
                {
                    title: "Makine ID",

                    dataIndex: "machineId",

                    key: "MachineId",

                    width: 150,

                    sorter: (a: any, b: any) => (a.machineId ? 1 : -1),
                },
                {
                    title: "Makine Kategori ID",

                    dataIndex: "machineCategoryId",

                    key: "MachineCategoryId",

                    width: 150,
                    sorter: (a: any, b: any) => (a.machineCategoryId ? 1 : -1),
                },
                {
                    title: "Kategori Adı",

                    dataIndex: "machineCategory",

                    key: "MachineCategory",

                    width: 150,
                    sorter: (a: any, b: any) => a.machineCategory.localeCompare(b.machineCategory),
                },

                {
                    title: "Makine Alt Kategori ID",

                    dataIndex: "machineSubCategoryId",

                    key: "MachineSubCategoryId",

                    width: 150,
                    sorter: (a: any, b: any) => (a.machineSubCategoryId ? 1 : -1),
                },
                {
                    title: "Alt Kategori Adı",

                    dataIndex: "machineSubCategory",

                    key: "MachineSubCategory",

                    width: 150,
                    sorter: (a: any, b: any) => a.machineSubCategory.localeCompare(b.machineSubCategory),
                },
                {
                    title: "Marka",

                    dataIndex: "machineBrand",

                    key: "MachineBrand",

                    width: 150,
                    sorter: (a: any, b: any) => a.machineBrand.localeCompare(b.machineBrand),
                },
                {
                    title: "Model",

                    dataIndex: "machineModel",

                    key: "MachineModel",

                    width: 150,
                    sorter: (a: any, b: any) => a.machineModel.localeCompare(b.machineModel),
                },

                {
                    title: "Makine Onay Durumu",

                    dataIndex: "machineApproval",

                    key: "MachineApproval",

                    width: 150,
                },
                {
                    title: "Makine Hizmet Bölgeleri",

                    dataIndex: "machineServiceRegions",

                    key: "MachineServiceRegions",

                    width: 150,
                },

                {
                    title: "Saatlik Ücret",

                    dataIndex: "machineHourlyPrice",

                    key: "MachineHourlyPrice",

                    width: 150,
                },
                {
                    title: "Saatlik Birim",

                    dataIndex: "machineCurrencyTypeHourlyName",

                    key: "MachineCurrencyTypeHourlyName",

                    width: 150,
                },

                {
                    title: "Günlük Ücret",

                    dataIndex: "machineDailyPrice",

                    key: "MachineDailyPrice",

                    width: 150,
                },
                {
                    title: "Günlük Birim",

                    dataIndex: "machineCurrencyTypeDailyName",

                    key: "MachineCurrencyTypeDailyName",

                    width: 150,
                },

                {
                    title: "Aylık Ücret",

                    dataIndex: "machineMonthlyPrice",

                    key: "MachineMonthlyPrice",

                    width: 150,
                },

                {
                    title: "Aylık Birim",

                    dataIndex: "machineCurrencyTypeMonthlyName",

                    key: "MachineCurrencyTypeMonthlyName",

                    width: 150,
                },

                {
                    title: "Yıllık Ücret",

                    dataIndex: "machineYearlyPrice",

                    key: "MachineYearlyPrice",

                    width: 150,
                },
                {
                    title: "Yıllık Birim",

                    dataIndex: "machineCurrencyTypeYearlyName",

                    key: "MachineCurrencyTypeYearlyName",

                    width: 150,
                },
                {
                    title: "Matched",
                    dataIndex: "matched",
                    key: "matched",
                    sorter: (a: any, b: any) => (a.matched ? 1 : -1),
                    render: () => "",
                },
            ];

            const tableColumn: tableColumns[] = [
                {
                    title: "Şirket ID",

                    dataIndex: "lessorCompanyId",

                    key: "LessorCompanyId",

                    width: 150,
                },

                {
                    title: "Şirket Adı",

                    dataIndex: "lessorCompaniesName",

                    key: "LessorCompaniesName",

                    width: 150,
                },

                {
                    title: "Kontak Ad Soyad",

                    dataIndex: "lessorCompanyContactName",

                    key: "LessorCompanyContactName",

                    width: 150,
                },
                {
                    title: "Kontak Telefon No",

                    dataIndex: "lessorCompanyPhone",

                    key: "LessorCompanyPhone",

                    width: 150,
                },
                {
                    title: "Kontak e-posta",

                    dataIndex: "lessorCompanyEmail",

                    key: "lessorCompanyEmail",

                    width: 150,
                },
                {
                    title: "Kayıtlı Makine Sayısı",

                    dataIndex: "lessorCompanyRegisteredMachineCount",

                    key: "lessorCompanyRegisteredMachineCount",

                    width: 150,
                },
                {
                    title: "Hizmet Bölgeleri",

                    dataIndex: "lessorCompanyLocation",

                    key: "LessorCompanyLocation",

                    width: 150,
                },
                {
                    title: "Makine ID",

                    dataIndex: "machineId",

                    key: "MachineId",

                    width: 150,
                },
                {
                    title: "Makine Kategori ID",

                    dataIndex: "machineCategoryId",

                    key: "MachineCategoryId",

                    width: 150,
                },
                {
                    title: "Kategori Adı",

                    dataIndex: "machineCategory",

                    key: "MachineCategory",

                    width: 150,
                },

                {
                    title: "Makine Alt Kategori ID",

                    dataIndex: "machineSubCategoryId",

                    key: "MachineSubCategoryId",

                    width: 150,
                },
                {
                    title: "Alt Kategori Adı",

                    dataIndex: "machineSubCategory",

                    key: "MachineSubCategory",

                    width: 150,
                },
                {
                    title: "Marka",

                    dataIndex: "machineBrand",

                    key: "MachineBrand",

                    width: 150,
                },
                {
                    title: "Model",

                    dataIndex: "machineModel",

                    key: "MachineModel",

                    width: 150,
                },

                {
                    title: "Makine Onay Durumu",

                    dataIndex: "machineApproval",

                    key: "MachineApproval",

                    width: 150,
                },
                {
                    title: "Makine Hizmet Bölgeleri",

                    dataIndex: "machineServiceRegions",

                    key: "MachineServiceRegions",

                    width: 150,
                },

                {
                    title: "Görsel 1",

                    dataIndex: "image1",

                    key: "image1",

                    width: 150,
                },

                {
                    title: "Görsel 2",

                    dataIndex: "image2",

                    key: "image2",

                    width: 150,
                },
                {
                    title: "Görsel 3",

                    dataIndex: "image3",

                    key: "image3",

                    width: 150,
                },
                {
                    title: "Görsel 4",

                    dataIndex: "image4",

                    key: "image4",

                    width: 150,
                },
                {
                    title: "Görsel 5",

                    dataIndex: "image5",

                    key: "image5",

                    width: 150,
                },
                {
                    title: "Görsel 6",

                    dataIndex: "image6",

                    key: "image6",

                    width: 150,
                },

                {
                    title: "Saatlik Ücret",

                    dataIndex: "machineHourlyPrice",

                    key: "MachineHourlyPrice",

                    width: 150,
                },
                {
                    title: "Saatlik Birim",

                    dataIndex: "machineCurrencyTypeHourlyName",

                    key: "MachineCurrencyTypeHourlyName",

                    width: 150,
                },

                {
                    title: "Günlük Ücret",

                    dataIndex: "machineDailyPrice",

                    key: "MachineDailyPrice",

                    width: 150,
                },
                {
                    title: "Günlük Birim",

                    dataIndex: "machineCurrencyTypeDailyName",

                    key: "MachineCurrencyTypeDailyName",

                    width: 150,
                },

                {
                    title: "Aylık Ücret",

                    dataIndex: "machineMonthlyPrice",

                    key: "MachineMonthlyPrice",

                    width: 150,
                },

                {
                    title: "Aylık Birim",

                    dataIndex: "machineCurrencyTypeMonthlyName",

                    key: "MachineCurrencyTypeMonthlyName",

                    width: 150,
                },

                {
                    title: "Yıllık Ücret",

                    dataIndex: "machineYearlyPrice",

                    key: "MachineYearlyPrice",

                    width: 150,
                },
                {
                    title: "Yıllık Birim",

                    dataIndex: "machineCurrencyTypeYearlyName",

                    key: "MachineCurrencyTypeYearlyName",

                    width: 150,
                },
                {
                    title: "Oluşturma Tarihi",

                    dataIndex: "machineCreationDate",

                    key: "machineCreationDate",

                    width: 150,
                },
                {
                    title: "Güncelleme Tarihi",

                    dataIndex: "machineModifiedDate",

                    key: "machineModifiedDate",

                    width: 150,
                },
                {
                    title: "Onay Tarihi",

                    dataIndex: "machineApprovalDate",

                    key: "machineApprovalDate",

                    width: 150,
                },
                {
                    title: "Şirket Oluşma Tarihi",

                    dataIndex: "lessorCompanyCreationDate",

                    key: "lessorCompanyCreationDate",

                    width: 150,
                },
            ];
            const sheetColumns = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            var result = checkDataIndexMissmatch(sheetColumns[0], tableColumn);
            setFileName(file.name);
            if (result === "Veri ve tablo sütünları arasında eşleşme tamamlandı.") {
                setColumns(tableColumns);
                setTable(jsonData);
                setUploadedExcelData(jsonData as ReportMachinesResponseViewModel[]);
                setInfoMessage(undefined);
                setLoading(false);
                setIsSuccess(true);
            } else {
                setInfoMessage({ message: result.toString(), color: "red" });
                setIsSuccess(false);
                setLoading(false);
            }
        } else {
            console.error("probem");
            return false;
        }
    };

    // Bu fonksiyon, uploadedExcelData dizisini PostRentalMachine dizisine dönüştürür
    const convertToPostRentalMachine = (uploadedExcelData: any[]): BatchMachineInfoUpdate[] => {
        // PostRentalMachine dizisini boş olarak oluştur
        var postRentalMachines: BatchMachineInfoUpdate[] = [];
        // uploadedExcelData dizisinin her bir elemanı için döngüye gir
        for (let item of uploadedExcelData) {
            // Kiralama tipleri dizisini boş olarak oluştur
            var rentalTypes: MachineRentalTypeCreateModel[] | undefined = [];
            // Saatlik, günlük, aylık ve yıllık kiralama tipleri için döngüye gir
            for (let i = 1; i <= 4; i++) {
                let priceType = ["Hourly", "Daily", "Monthly", "Yearly"][i - 1];
                const parsedPrice = parseFloat(item[`machine${priceType}Price`]);
                const currencyName = item[`machineCurrencyType${priceType}Name`];
                const upperCurrencyName = currencyName ? currencyName.toUpperCase() : item[`machineCurrencyType${i}Name`];
                if (isNaN(parsedPrice) || item[`machine${i}Price`] === `${i}. Ücret Yok` || upperCurrencyName === "BİRİM YOK") {
                    //   rentalTypes.push(undefined);
                    continue;
                }
                // Para birimi adına göre para birimi ID'sini bul
                let currencyId: number;
                switch (upperCurrencyName) {
                    case "TL":
                        currencyId = 1;
                        break;
                    case "USD":
                        currencyId = 2;
                        break;
                    case "EUR":
                        currencyId = 3;
                        break;
                    default:
                        continue;
                }

                // Oluşturulan rentalTypes nesnesini diziye ekle
                rentalTypes.push({
                    rentalTypeId: i,
                    currencyId: currencyId,
                    price: parsedPrice,
                });
            }
            // Oluşturulan PostRentalMachine nesnesini diziye ekle
            postRentalMachines.push({
                machineId: item.machineId,
                rentalTypes: rentalTypes,
            });
        }
        // Oluşturulan PostRentalMachine dizisini döndür

        return postRentalMachines;
    };

    const compareDiffrence = async () => {
        setTable([]);
        setLoading(true);
        setChangedMachineNumber(undefined);
        var convertResult = convertToPostRentalMachine(uploadedExcelData as any);
        const machineCompareResult: BatchMachineInfoUpdateListValidatedModel | undefined = await doBatchMachineCompare(convertResult);
        var machineCompareResultData: any = machineCompareResult?.data;
        SetOutGoingUpdateMachines(machineCompareResultData);
        const matchedData = uploadedExcelData?.map((item) => {
            const isMatched =
                uploadedExcelData?.some((e: any) => e.machineId === item.machineId) &&
                machineCompareResultData?.some((e: any) => e.machineId.toString() === item.machineId?.toString());
            return { ...item, matched: isMatched };
        });
        var matchedTrue = matchedData?.filter((e) => e?.matched === true).length;
        matchedData?.sort((a, b) => b.matched - a.matched);
        setTable(matchedData);
        setChangedMachineNumber(matchedTrue);
        setLoading(false);
        setDisableUpdateButton(matchedTrue === 0);
    };

    const checkDataIndexMissmatch = (data: any, columns: tableColumns[]) => {
        // Tablo sütünlarının tüm dataIndex değerlerini bir diziye dönüştür
        console.log("data: ", data);
        var columnKeys = columns.map((column) => column.dataIndex);

        // Veri dizisinin her bir elemanı için
        for (var i = 0; i < data.length; i++) {
        
            for (var j = 0; j < data.length; j++) {
                if (!columnKeys.includes(data[j])) {
                    return "Veri özellikleri uyuşmuyor. Taslak excelinde " + data[j] + " adında bir sütun bulunmamaktadır. ";
                } 
                else if (!data.includes(columnKeys[j])) {
                    return "Veri özellikleri uyuşmuyor. Taslak excelinde " + columnKeys[j] + " sütunu eksik. ";
                }
            }
        }
        // Eğer tüm veri özellikleri tablo sütünlarında bulunuyorsa
        return "Veri ve tablo sütünları arasında eşleşme tamamlandı.";
    };

    //MODAL
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [openResultModal, setOpenResultModal] = useState(false);

    const [confirmLoading, setConfirmLoading] = useState(false);

    const handleCancel = () => {
        console.log("Clicked cancel button");
        setOpenConfirmModal(false);
    };

    const handleOkResultModal = async () => {
        setOpenResultModal(false);
    };
    const handleOk = async () => {
        setConfirmLoading(true);
        setTimeout(async () => {
            setOpenConfirmModal(false);
            setConfirmLoading(false);
            setLoading(true);
            setInfoMessage({ message: "Verileriniz güncelleniyor. Lütfen Bekleyin", color: "wait" });
            var UpdateResult = await doBatchMachineUpdate(outGoingUpdateMachines);
            if (UpdateResult?.data) {
                setInfoMessage({
                    message: `Güncelleme İşlemi başarılı, günncelenen makine adedi: ${UpdateResult.data?.length}`,
                    color: "green",
                });
                setDisableUpdateButton(true);
                setLoading(false);
                setOpenResultModal(true);
            }
            if (UpdateResult?.error) {
                setInfoMessage({ message: UpdateResult?.error.title, color: "red" });
                setOpenResultModal(true);
                setDisableUpdateButton(true);
                setLoading(false);
            }
        }, 500);
    };

    return (
        <ListContainer>
            <BoxContainer>
                <Row justify="space-between" align="middle">
                    <Col>
                        <h3 style={{ fontSize: "1.25rem", fontWeight: "700" }}>{t("batch.machines.title")}</h3>
                    </Col>
                </Row>

                <Card title="Dosya Yükleyin (CSV veya XLSX)">
                    <Space direction="vertical" size="large" style={{ display: "flex" }}>
                        <Row align="middle" justify="space-between">
                            <Col>
                                <Button
                                    type="primary"
                                    htmlType="button"
                                    ghost
                                    onClick={() => {
                                        if (fileInput.current) {
                                            fileInput.current.click();
                                            setUploadedExcelData([]);
                                            setTable([]);
                                        }
                                    }}
                                >
                                    Dosya yükleyin
                                </Button>
                            </Col>
                            <input
                                ref={fileInput}
                                hidden
                                id="file1"
                                type="file"
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                onChange={(e) => handleFileUpload(e)}
                            />
                            {fileName && (
                                <>
                                    <Col span={12}>
                                        {infoMessage ? (
                                            <>
                                                {infoMessage?.color === "green" && (
                                                    <Row justify="center" align="middle" style={{ marginBottom: 15 }}>
                                                        <CheckCircleFilled style={{ fontSize: 30, color: "#feb111", marginRight: 5 }} />{" "}
                                                        <strong style={{ fontSize: 18, fontWeight: 700 }}>{infoMessage?.message}</strong>
                                                    </Row>
                                                )}
                                                {infoMessage?.color === "red" && (
                                                    <Row justify="center" align="middle" style={{ marginBottom: 15 }}>
                                                        <CloseCircleOutlined style={{ fontSize: 30, color: "red", marginRight: 5 }} />
                                                        <strong style={{ fontSize: 18, fontWeight: 700 }}>{infoMessage?.message}</strong>
                                                    </Row>
                                                )}
                                                {infoMessage?.color === "wait" && (
                                                    <Row justify="center" align="middle" style={{ marginBottom: 15 }}>
                                                        <LoadingOutlined style={{ fontSize: 30, color: "#feb111", marginRight: 5 }} />
                                                        <strong style={{ fontSize: 18, fontWeight: 700 }}>{infoMessage?.message}</strong>
                                                    </Row>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <Row justify="center" align="middle" style={{ marginBottom: 15 }}>
                                                    <CheckCircleFilled style={{ marginRight: 5, color: "#feb111", fontSize: 30 }} />
                                                    <strong style={{ fontSize: 18, fontWeight: 700 }}>
                                                        Veri ve tablo sütünları arasında eşleşme sağlandı.
                                                    </strong>
                                                </Row>

                                                <Title level={5} style={{ color: "#feb111" }}>
                                                    Dosya Adı: <span style={{ color: "black" }}>{fileName}</span>
                                                </Title>
                                                <Title level={5} style={{ color: "#feb111" }}>
                                                    Dosyadaki Makine Sayısı: <span style={{ color: "black" }}>{table.length}</span>
                                                </Title>
                                                {changedMachineNumber !== undefined && (
                                                    <Title level={5} style={{ color: "#feb111" }}>
                                                        Değişiklik Yapılmış Makine Sayısı:
                                                        <span style={{ color: "black" }}>{changedMachineNumber}</span>
                                                    </Title>
                                                )}
                                            </>
                                        )}
                                    </Col>

                                    {changedMachineNumber === undefined ? (
                                        <Col flex={"end"}>
                                            <Button
                                                disabled={!isSuccess}
                                                onClick={compareDiffrence}
                                                block
                                                type="primary"
                                                htmlType="button"
                                                ghost
                                            >
                                                Kontrol Et
                                            </Button>
                                        </Col>
                                    ) : (
                                        <Col flex={"end"}>
                                            <PrimaryButton
                                                disabled={disableUpdateButton}
                                                onClick={() => setOpenConfirmModal(!openConfirmModal)}
                                            >
                                                <div style={{ display: "flex", alignItems: "center" }}>Güncelle</div>
                                            </PrimaryButton>
                                        </Col>
                                    )}
                                </>
                            )}
                        </Row>
                        <Modal
                            title={
                                <Row align="middle" gutter={[18, 18]}>
                                    {" "}
                                    <WarningFilled style={{ fontSize: 30, color: "#ffc73b", marginRight: 5 }} />{" "}
                                    <strong>Güncelleme Onayı</strong>
                                </Row>
                            }
                            visible={openConfirmModal}
                            onOk={handleOk}
                            confirmLoading={confirmLoading}
                            onCancel={handleCancel}
                        >
                            <p>Toplamda {changedMachineNumber} makine kaydı değişikliği var, makineleri güncellemek istiyor musunuz?</p>
                        </Modal>

                        <Modal
                            title={
                                infoMessage?.color === "green" ? (
                                    <Row align="middle" gutter={[18, 18]}>
                                        <CheckCircleFilled style={{ fontSize: 30, color: "#feb111", marginRight: 5 }} />{" "}
                                        <strong>Güncelleme Başarılı</strong>
                                    </Row>
                                ) : (
                                    <Row align="middle" gutter={[18, 18]}>
                                        <CloseCircleOutlined style={{ fontSize: 30, color: "red", marginRight: 5 }} />{" "}
                                        <strong>Güncelleme Yapılamadı</strong>
                                    </Row>
                                )
                            }
                            visible={openResultModal}
                            onOk={handleOkResultModal}
                            onCancel={handleOkResultModal}
                            destroyOnClose={openResultModal}
                            closable={true}
                        >
                            <p>{infoMessage?.message}</p>
                        </Modal>

                        {table && (
                            <Table
                                rowClassName={(record) => (record.matched ? "matched-row" : "")}
                                loading={loading}
                                scroll={{ x: 1500 }}
                                columns={columns}
                                dataSource={table}
                            />
                        )}
                    </Space>
                </Card>
            </BoxContainer>
        </ListContainer>
    );
};
export default BatchMachineUpdate;
