/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactFormDetailModel
 */
export interface ContactFormDetailModel {
    /**
     * 
     * @type {number}
     * @memberof ContactFormDetailModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactFormDetailModel
     */
    firmName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactFormDetailModel
     */
    nameLastname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactFormDetailModel
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactFormDetailModel
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactFormDetailModel
     */
    message?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ContactFormDetailModel
     */
    creationDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ContactFormDetailModel
     */
    isRead?: boolean;
}

export function ContactFormDetailModelFromJSON(json: any): ContactFormDetailModel {
    return ContactFormDetailModelFromJSONTyped(json, false);
}

export function ContactFormDetailModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactFormDetailModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'firmName': !exists(json, 'firmName') ? undefined : json['firmName'],
        'nameLastname': !exists(json, 'nameLastname') ? undefined : json['nameLastname'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'isRead': !exists(json, 'isRead') ? undefined : json['isRead'],
    };
}

export function ContactFormDetailModelToJSON(value?: ContactFormDetailModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firmName': value.firmName,
        'nameLastname': value.nameLastname,
        'email': value.email,
        'phone': value.phone,
        'message': value.message,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'isRead': value.isRead,
    };
}

