import { Col, Form, FormInstance, InputNumber, Row, Select } from "antd";
import BigboxContainer from "../components/containers/bigbox-container";
import { t } from "../translate";
import { useEffect, useState } from "react";
import { BasicModel } from "../service/models";
import { useAtom } from "jotai";
import { loadingAtom } from "../store/global-atoms";
import { useSecureService } from "../store/service-atoms";
import { formatPriceInput } from "../helpers/formatters"

type ISaleMachinePricePartProps = {
    depositFee?: string | undefined;
    machineGoCommission?: string | undefined;
    totalPriceForMachineOwner?: string | undefined;
    defaultCurrencyId?: number;
    form?: FormInstance<any>;
    onPriceChange: (val: any) => void;
    onCurrencyChange: (val: string | null | undefined) => void;
};

const SaleMachinePricePart = (props: ISaleMachinePricePartProps) => {
    const [availableCurrencies, setAvailableCurrencies] = useState<BasicModel[] | undefined>(undefined);
    const [loading] = useAtom(loadingAtom);
    const { doGetCurrencies } = useSecureService();

    useEffect(() => {
        const getCurrencies = async () => {
            const currencies = await doGetCurrencies();
            setAvailableCurrencies(currencies);
        };
        getCurrencies();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (props.defaultCurrencyId && availableCurrencies && availableCurrencies.length > 0) {
            props.form?.setFieldsValue({
                machineSalePriceCurrencyName: availableCurrencies.find(e => e.id === props.defaultCurrencyId)?.name,
            });
        }
    }, [props.defaultCurrencyId, availableCurrencies, props.form]);

    return (
        <BigboxContainer title={t("machines.add.pricePartTitle")}>
            <Row gutter={[16, 32]}>
                <Col md={8} xs={24}>
                    <Form.Item
                        label={t("common.currency")}
                        name="machineSalePriceCurrencyName"
                        rules={[
                            {
                                required: true,
                                message: t("common.generic-required-message"),
                            },
                        ]}
                    >
                        <Select
                            onChange={(e) => props.onCurrencyChange(e)}
                            loading={loading}
                            placeholder={t("machines.add.price.currencyPlaceholder")}
                        >
                            {availableCurrencies?.map((curr) => (
                                <Select.Option value={curr.name}>{curr.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col md={16} xs={24}>
                    <Form.Item
                        label={t("common.price-2")}
                        name="machineSalePrice"
                        rules={[
                            {
                                required: true,
                                message: t("common.generic-required-message"),
                            },
                        ]}
                    >
                        <InputNumber
                            placeholder={t("machines.add.price.inputPlaceholder")}
                            onKeyDown={(event) => {
                                if ((event.key !== "Delete" && event.key !== "Backspace") && !/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            formatter={value =>{
                                return formatPriceInput(value?.toString() ?? "");
                            }}
                            parser={value => {
                                return formatPriceInput(value?.toString() ?? "").replaceAll(".", '');
                            }} 
                            onWheel={(e: any) => e.target.blur()}
                            controls={false}
                            type={"text"}
                            onChange={(e) => props.onPriceChange(e)}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </BigboxContainer>
    );
};

export default SaleMachinePricePart;
