import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import LightTitle from "../components/atomics/light-title";
import { BasicModel } from "../service";
import { useSecureService } from "../store/service-atoms";
import { t } from "../translate";
import { ViewPartProps } from "../viewmodels/view-page-part-props";
import BorderlessContainer from "../components/containers/borderless-container";

const MachineSalePricePart = (props: ViewPartProps) => {
    const { doGetCurrencies } = useSecureService();
    const [selectedCurrency, setSelectedCurrency] = useState<BasicModel | undefined>(undefined);

    useEffect(() => {
        const getServiceDatas = async () => {
            const result = await doGetCurrencies();
            let selectedCurr = result?.find((val) => val.id === props.machine?.machineSalePriceCurrencyId);
            setSelectedCurrency(selectedCurr);
        };
        if (props.viewType === "machine") getServiceDatas();
        // eslint-disable-next-line
    }, [props.machine, selectedCurrency]);

    return (
        <BorderlessContainer title={t("machines.price-part.price")}>
            {props.machine && (
                <Row gutter={[16, 16]}>
                    <Col md={24} xs={24}>
                        <Row justify="space-between">
                            <Col>
                                <LightTitle>{t("machines.price-part.machine.owner.price")}</LightTitle>
                            </Col>
                            <Col>{props.machine.machineSalePrice + " " + (selectedCurrency?.name || "")}</Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </BorderlessContainer>
    );
};

export default MachineSalePricePart;
