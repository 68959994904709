import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import BaseLayout from "./base-layout";
import { Col, Layout, Menu, Row, Spin } from "antd";
import styles from "../../styles/PostloginLayout.module.css";
import { useAtom } from "jotai";
import { t } from "../../translate";
import { Link, useNavigate } from "react-router-dom";
import { useSecureService } from "../../store/service-atoms";
import { userAtom } from "../../store/global-atoms";
import NotificationBell from "../atomics/notification-bell";
import SubMenu from "antd/lib/menu/SubMenu";
import PrimaryButton from "../atomics/buttons/primary-button";
import { ReactComponent as User } from "../assets/profile.svg";

const { Header, Content, Footer, Sider } = Layout;

export type PostloginLayoutProps = {
    children: any;
    header?: string | ReactElement;
    loading?: boolean;
    footer?: string | ReactElement;
};
const PostloginLayout = (props: PostloginLayoutProps) => {
    const navigate = useNavigate();
    const [selectedMenuItem, setSelectedMenuItem] = useState(0); 
    const { doLogout } = useSecureService();
    const [user] = useAtom(userAtom);
    const navigation = useRef(useNavigate());

    const [key, url] = useMemo(() => {
        let key = "";

        switch (window.location.pathname) {
            case "/rent/commission-rates":
                setSelectedMenuItem(1);
                break; 
            case "/rent/machines":
            case "/rent/machines/view": 
            case "/rent/machines/edit":
                setSelectedMenuItem(2);
                break;
            case "/rent/pending-approval":
            case "/rent/pending-approval/detail":
            case "/rent/pending-approval/edit":
                setSelectedMenuItem(3);
                break;
            case "/rent/showcases":
                setSelectedMenuItem(4);
                break;
            case "/rent/requests": 
            case "/rent/requests/view":
                setSelectedMenuItem(5);
                break;
            case "/rent/offers":
            case "/rent/offers/view":
                setSelectedMenuItem(6);
                break;
            case "/sale/pending-approval":
            case "/sale/pending-approval/detail":
            case "/sale/pending-approval/edit":
                setSelectedMenuItem(7);
                break;
            case "/sale/machines":
            case "/sale/machines/view":
            case "/sale/machines/edit":
                setSelectedMenuItem(8);
                break;
            case "/sale/removed":
            case "/sale/removed/view":
                setSelectedMenuItem(9);
                break;
            case "/sale/showcases":
                setSelectedMenuItem(10);
                break;
            case "/renter-company": 
            case "/renter-company/view":
                setSelectedMenuItem(11);
                break;
            case "/lessor-company":
            case "/lessor-company/view":
                setSelectedMenuItem(12);
                break;
            case "/applicants":
                setSelectedMenuItem(13);
                break;
            case "/batch-machine-update":
                setSelectedMenuItem(14);
                break;
            case "/reports":
                setSelectedMenuItem(15);
                break;
            default: 
                setSelectedMenuItem(0);
                break;
        }

        if (window.location.pathname.includes("rent/")) key = "rent";
        else if (window.location.pathname.includes("sale/")) key = "sale";
        else key = "";
        return [key, window.location.pathname];
    }, []);

    useEffect(() => {
        if (user === undefined) {
            navigation.current("/");
        }
    }, [user]);

    const selectionChange = async (item: any) => {
        navigation.current(item.key);
    };
    const onLogoutClick = async () => {
        await doLogout();
    };

    console.log(user);
    return (
        <BaseLayout>
            <Sider breakpoint="lg" width={220} collapsedWidth="0" className={styles.sider}>
                <div className={styles.logoContainer} onClick={() => navigate("/rent/machines")}>
                    <img src="/assets/logo-inverse.png" alt="Machinego Logo" width={"100%"}></img>
                </div>
                <div className={styles.menuContainer}>
                    <Menu
                        theme="dark"
                        style={{ backgroundColor: "#282828", flex: "1" }}
                        defaultOpenKeys={[key]}
                        selectedKeys={[url]}
                        mode="inline"
                        className={styles.menu}
                        onSelect={selectionChange}
                    >
                        {user?.role === "Admin" && (
                            <>
                                <SubMenu
                                    style={{ backgroundColor: "#282828" }}
                                    className={styles.subMenu}
                                    theme="dark"
                                    title={t("menu.rentalOperations")}
                                    key={"rent"}
                                >
                                    <Menu.Item key="/rent/commission-rates">
                                        <span className={selectedMenuItem === 1 ? styles.selectedMenuItem : undefined}>{t("menu.commissionRate")}</span>
                                        <Link to={"/rent/commission-rates"} />
                                    </Menu.Item>
                                    <Menu.Item key={"/rent/machines"}>
                                        <span className={selectedMenuItem === 2 ? styles.selectedMenuItem : undefined}>{t("menu.listMachines")}</span>
                                        <Link
                                            onAuxClick={() => {
                                                navigation.current("/rent/machines");
                                            }}
                                            to={"/rent/machines"}
                                        />
                                    </Menu.Item>
                                    <Menu.Item key="/rent/pending-approval">
                                        <span className={selectedMenuItem === 3 ? styles.selectedMenuItem : undefined}>{t("menu.pendingApproval")}</span>
                                        <Link to={"/rent/pending-approval"} />
                                    </Menu.Item>
                                    <Menu.Item key="/rent/showcases">
                                        <span className={selectedMenuItem === 4 ? styles.selectedMenuItem : undefined}>{t("menu.showcases")}</span>
                                        <Link to={"/rent/showcases"} />
                                    </Menu.Item>
                                    <Menu.Item key="/rent/requests">
                                        <span className={selectedMenuItem === 5 ? styles.selectedMenuItem : undefined}>{t("menu.requests")}</span>
                                        <Link to={"/rent/requests"} />
                                    </Menu.Item>
                                    <Menu.Item key="/rent/offers">
                                        <span className={selectedMenuItem === 6 ? styles.selectedMenuItem : undefined}>{t("menu.offers")}</span>
                                        <Link to={"/rent/offers"} />
                                    </Menu.Item>
                                </SubMenu>
                                <SubMenu
                                    style={{ backgroundColor: "#282828" }}
                                    className={styles.subMenu}
                                    theme="dark"
                                    title={t("menu.onSaleOperations")}
                                    key={"sale"}
                                >
                                    <Menu.Item key="/sale/pending-approval">
                                        <span className={selectedMenuItem === 7 ? styles.selectedMenuItem : undefined}>{t("menu.onSalePendingApproval")}</span>
                                        <Link to={"/sale/pending-approval"} />
                                    </Menu.Item>
                                    <Menu.Item key="/sale/machines">
                                        <span className={selectedMenuItem === 8 ? styles.selectedMenuItem : undefined}>{t("menu.onSaleMachines")}</span>
                                        <Link to={"/sale/machines"} />
                                    </Menu.Item>
                                     <Menu.Item key="/sale/removed">
                                        <span className={selectedMenuItem === 9 ? styles.selectedMenuItem : undefined}>{t("menu.removed-machines")}</span>
                                        <Link to={"/sale/removed"} />
                                    </Menu.Item> 
                                    {/* <Menu.Item key="/sale/reservations">
                                        {t("menu.onSaleReservations")}
                                        <Link to={"/sale/reservations"} />
                                    </Menu.Item> */}
                                    {/* <Menu.Item key="/sale/expertise-requests">
                                        {t("menu.onSaleExpertiseRequests")}
                                        <Link to={"/sale/expertise-requests"} />
                                    </Menu.Item> */}
                                    {/* <Menu.Item key="/sale/deposit-fee">
                                        {t("menu.onSaleDownPayment")}
                                        <Link to={"/sale/deposit-fee"} />
                                    </Menu.Item> */}
                                    {/* <Menu.Item key="/sale/commission-rates">
                                        {t("menu.onSaleCommisionRate")}
                                        <Link to={"/sale/commission-rates"} />
                                    </Menu.Item> */}
                                    <Menu.Item key="/sale/showcases">
                                        <span className={selectedMenuItem === 10 ? styles.selectedMenuItem : undefined}>{t("menu.onSaleShowCases")}</span>
                                        <Link to={"/sale/showcases"} />
                                    </Menu.Item>
                                </SubMenu>
                                <Menu.Item key="/renter-company">
                                    <span className={selectedMenuItem === 11 ? styles.selectedMenuItem : undefined}>{t("menu.listRenters")}</span>
                                    <Link to={"/renter-company"} />
                                </Menu.Item>
                                <Menu.Item key="/lessor-company">
                                    <span className={selectedMenuItem === 12 ? styles.selectedMenuItem : undefined}>{t("menu.listLessors")}</span>
                                    <Link to={"/lessor-company"} />
                                </Menu.Item>
                                <Menu.Item key="/applicants">
                                    <span className={selectedMenuItem === 13 ? styles.selectedMenuItem : undefined}>{t("menu.contactUs")}</span>
                                    <Link to={"/applicants"} />
                                </Menu.Item>
                                <Menu.Item key="/batch-machine-update">
                                    <span className={selectedMenuItem === 14 ? styles.selectedMenuItem : undefined}>{t("menu.batchMachineUpdate")}</span>
                                </Menu.Item>
                                <Menu.Item key="/reports">
                                    <span className={selectedMenuItem === 15 ? styles.selectedMenuItem : undefined}>{t("menu.reports")}</span>
                                    <Link to={"/reports"} />
                                </Menu.Item>
                            </>
                        )}
                    </Menu>
                    <Row className={styles.userOperations}>
                        <Col>
                            <div className={styles.userCard}>
                                <User />
                                <span>{user?.name + " " + user?.lastname}</span>
                            </div>
                        </Col>
                        <Col>
                            <PrimaryButton onClick={onLogoutClick} style={{ padding: "5px 0", height: "fit-content", margin: "0" }}>
                                {t("menu.logout")}
                            </PrimaryButton>
                        </Col>
                    </Row>
                </div>
                {/* <Menu.Item key="0">{t("menu.logout")}</Menu.Item> */}
            </Sider>
            <Layout style={{ backgroundColor: "transparent", height: "100vh", display: "flex", justifyContent: "space-between" }}>
                <Header className={styles.header}>
                    <div className={styles.headerTopRight} style={{ padding: 15 }}>
                        <div>
                            <NotificationBell userId={user?.id.toString()} />
                        </div>
                    </div>
                </Header>
                {props.loading && (
                    <Spin
                        size="large"
                        tip="Lütfen Bekleyiniz"
                        className={styles.spinMobile}
                    />
                )}
                <Content hidden={props.loading} className={styles.content}>{props.children}</Content>
                <Footer className={styles.footer}>{props.footer || t("copyright")}</Footer>
            </Layout>
        </BaseLayout>
    );
};

export default PostloginLayout;
