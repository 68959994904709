/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactFormCreateModel
 */
export interface ContactFormCreateModel {
    /**
     * 
     * @type {string}
     * @memberof ContactFormCreateModel
     */
    firmName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactFormCreateModel
     */
    nameLastname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactFormCreateModel
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactFormCreateModel
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactFormCreateModel
     */
    message?: string | null;
}

export function ContactFormCreateModelFromJSON(json: any): ContactFormCreateModel {
    return ContactFormCreateModelFromJSONTyped(json, false);
}

export function ContactFormCreateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactFormCreateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firmName': !exists(json, 'firmName') ? undefined : json['firmName'],
        'nameLastname': !exists(json, 'nameLastname') ? undefined : json['nameLastname'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function ContactFormCreateModelToJSON(value?: ContactFormCreateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firmName': value.firmName,
        'nameLastname': value.nameLastname,
        'email': value.email,
        'phone': value.phone,
        'message': value.message,
    };
}

